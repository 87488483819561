import storage from 'store'
import { login } from '@/api/login'
import { ACCESS_TOKEN, User_Id, CORPORATION, USERNAME, UNITS } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
// eslint-disable-next-line no-unused-vars
import jwt_decode from 'jwt-decode'
import { user_permission_list } from '@/api/user'
import { common_unit_list } from '@/api/common'
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    corporation: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_CORPORATION: (state, corporation) => {
      state.corporation = corporation
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          console.log(response, '返回值', jwt_decode(response.jwt))
          // 用户信息
          const result = response
          const userobj = result.user
          console.log(userobj, '用户信息')
          const AC_TOKEN = result.jwt
          const userId = jwt_decode(AC_TOKEN).user_id || 404
          storage.set(ACCESS_TOKEN, AC_TOKEN, 7 * 24 * 60 * 60 * 1000)
          storage.set(User_Id, userId, 2 * 24 * 60 * 60 * 1000)
          storage.set(USERNAME, userobj.nickname ? userobj.nickname : userobj.username, 2 * 24 * 60 * 60 * 1000)
          commit('SET_INFO', userobj)
          commit('SET_TOKEN', AC_TOKEN)
          commit('SET_NAME', { name: userobj.nickname ? userobj.nickname : userobj.username, welcome: welcome() })
          commit('SET_AVATAR', userobj.avatar)
          localStorage.userInfo = JSON.stringify(userobj)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }, userId) {
      console.log(userId, 'user_id')
      return new Promise((resolve, reject) => {
          user_permission_list({ }).then(({ data }) => {
            // 权限信息
            const resultData = data
            const ogrole = {}
            const view_jsonlist = resultData.map((item) => {
              return JSON.parse(item.view_json)
            })
            ogrole.permissions = view_jsonlist.flat()
            if (ogrole && ogrole.permissions.length > 0) {
              const role = ogrole
              role.permissions = ogrole.permissions
              role.permissions.map(per => {
                if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                  const action = per.actionEntitySet.map(action => { return action.defaultCheck ? action.action : '' })
                  per.actionList = action
                }
              })
              if (role.permissions && role.permissions.length > 0) {
                const newPermissions = []
                const newPermissionsId = []
                for (const permission of role.permissions) {
                  const number = newPermissionsId.indexOf(permission.permissionId)
                  if (number !== -1) {
                    // 存在
                    const exist_permission = newPermissions[number]
                    if (permission.actionList && permission.actionList.length > 0) {
                      permission.actionList.forEach(item => {
                        if (item !== '' && exist_permission && exist_permission.actionList && exist_permission.actionList.indexOf(item) === -1) {
                          newPermissions[number].actionList.push(item)
                        }
                      })
                    }
                  } else {
                    // 不存在
                    newPermissionsId.push(permission.permissionId)
                    newPermissions.push(permission)
                  }
                }
                role.permissions = newPermissions
              }
              role.permissionList = role.permissions.map(permission => { return permission.permissionId })
              resultData[0].permissionList = role.permissionList
              resolve(resultData[0])
              commit('SET_ROLES', ogrole.permissions)
              const userInfo = JSON.parse(localStorage.userInfo)
              commit('SET_INFO', userInfo)
            } else {
              reject(new Error('getInfo: roles must be a non-null array !'))
            }
          })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        storage.remove(ACCESS_TOKEN)
        window.localStorage.clear()
        resolve()

        /* logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
        }) */
      })
    },

    // 更换公司
    ChangeCorporation ({ commit, state }) {
      return new Promise((resolve) => {
        storage.set(CORPORATION, state)
        commit('SET_CORPORATION', state)
      })
    },
    CommonUnits ({ commit, state }) {
      common_unit_list().then(res => {
        const unit = {}
        res.data.forEach(function (item, index, self) {
          unit[item.code] = item.name
        })
        storage.set(UNITS, unit, 7 * 24 * 60 * 60 * 1000)
      })
      // common_country_list().then(res => {
      //   res.data.forEach(function (item, index, self) {
      //     Dictionaries.country[item.code] = item.name
      //   })
      // })
      // common_currency_list().then(res => {
      //   res.data.forEach(function (item, index, self) {
      //     Dictionaries.currency[item.code] = item.name
      //   })
      // })
    }
  }
}

export default user
