import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { Dictionaries } from '@/common/AllConstant'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log('=======', error)
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status >= 300 && error.response.status <= 400) {
      notification.warning({
        message: Dictionaries.returnCode[data.code] || '未知错误',
        description: data.msg
      })
    }
    if (error.response.status === 403) {
      notification.warning({
        message: '无权限',
        description: data.msg
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.warning({
        message: '未登录',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
      }
    }
    if (error.response.status >= 500) {
      notification.error({
        message: '服务器错误',
        description: data.msg
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = 'JWT ' + token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const { config } = response
  if ((config.method !== 'get') && response.data.code === 1000) {
    notification.success({
      message: '成功',
      description: response.data.msg
    })
  }
  if (response.data.code !== 1000 && (response.config.url !== '/user/jwt/' && (response.headers['content-type'] !== 'application/ms-excel')) && (response.config.url !== 'https://static.jnzbtz.com') && (response.config.url !== 'https://static.jnzbtz.com')) {
    notification.warning({
      message: Dictionaries.returnCode[response.data.code] || '未知错误',
      description: response.data.msg
    })
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
