import request from '@/utils/request'
const requestApi = {
  common_unit_list: '/unit/',
  common_unit_create: '/unit/',
  common_unit_update: '/unit/',
  common_unit_delete: '/unit/',
  common_country_list: '/country/',
  common_country_create: '/country/',
  common_country_update: '/country/',
  common_country_delete: '/country/',
  common_currency_list: '/currency/',
  common_currency_create: '/currency/',
  common_currency_update: '/currency/',
  common_currency_delete: '/currency/',
  common_logistics: '/logistics/'
}

export function common_unit_list (query) {
  return request({
    url: requestApi.common_unit_list,
    method: 'get',
    params: query
  })
}

export function common_unit_create (data) {
  return request({
    url: requestApi.common_unit_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function common_unit_update (data, goods_id) {
  return request({
    url: requestApi.common_unit_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function common_unit_delete (goods_id) {
  return request({
    url: requestApi.common_unit_delete + goods_id + '/',
    method: 'delete'
  })
}

export function common_country_list (query) {
  return request({
    url: requestApi.common_country_list,
    method: 'get',
    params: query
  })
}

export function common_country_create (data) {
  return request({
    url: requestApi.common_country_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function common_country_update (data, goods_id) {
  return request({
    url: requestApi.common_country_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function common_country_delete (goods_id) {
  return request({
    url: requestApi.common_country_delete + goods_id + '/',
    method: 'delete'
  })
}
export function common_currency_list (query) {
  return request({
    url: requestApi.common_currency_list,
    method: 'get',
    params: query
  })
}

export function common_currency_create (data) {
  return request({
    url: requestApi.common_currency_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function common_currency_update (data, goods_id) {
  return request({
    url: requestApi.common_currency_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function common_currency_delete (goods_id) {
  return request({
    url: requestApi.common_currency_delete + goods_id + '/',
    method: 'delete'
  })
}

export function common_logistics_list (query) {
  return request({
    url: requestApi.common_logistics,
    method: 'get',
    params: query
  })
}
